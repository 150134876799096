var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { getConfig } from './config';
import { failure, isLoading, isNotAsked, isSuccess, loading, notAsked, success } from './RemoteData';
function getAvailableStores(auth0AccessToken, pcsUrl) {
    return __awaiter(this, void 0, void 0, function () {
        var config, tokenExchangeResponse, storesResponse, currentUserResponse, requiredStorePermission, hasGlobalStorePermissions, availableStores, _loop_1, _i, _a, store;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    config = {
                        headers: {
                            authorization: "Bearer ".concat(auth0AccessToken)
                        }
                    };
                    return [4 /*yield*/, axios.get("".concat(pcsUrl, "/api/public/auth/v1/auth0-token-exchange"), config)];
                case 1:
                    tokenExchangeResponse = _b.sent();
                    if (config.headers) {
                        config.headers.authorization = "Bearer ".concat(tokenExchangeResponse.data.token);
                    }
                    return [4 /*yield*/, axios.get("".concat(pcsUrl, "/api/private/web/stores"), config)];
                case 2:
                    storesResponse = _b.sent();
                    return [4 /*yield*/, axios.get("".concat(pcsUrl, "/api/private/web/users/current"), config)];
                case 3:
                    currentUserResponse = _b.sent();
                    requiredStorePermission = ['VIEW_STATUS'];
                    hasGlobalStorePermissions = requiredStorePermission.every(function (requiredStorePermission) {
                        return currentUserResponse.data.globalStorePermissions.find(function (permission) { return permission === requiredStorePermission; });
                    });
                    availableStores = [];
                    if (hasGlobalStorePermissions) {
                        storesResponse.data.forEach(function (store) {
                            buildSmartAppUrl(pcsUrl, store);
                        });
                        availableStores.push.apply(availableStores, storesResponse.data);
                    }
                    else {
                        _loop_1 = function (store) {
                            var storePermissions = currentUserResponse.data.storePermissions[store.storeUuid];
                            if (storePermissions) {
                                var hasStorePermissions = requiredStorePermission.every(function (requiredStorePermission) {
                                    return storePermissions.find(function (permission) { return permission === requiredStorePermission; });
                                });
                                if (hasStorePermissions) {
                                    buildSmartAppUrl(pcsUrl, store);
                                    availableStores.push(store);
                                }
                            }
                        };
                        for (_i = 0, _a = storesResponse.data; _i < _a.length; _i++) {
                            store = _a[_i];
                            _loop_1(store);
                        }
                    }
                    availableStores.sort(function (a, b) { return a.name.localeCompare(b.name); });
                    return [2 /*return*/, availableStores];
            }
        });
    });
}
var buildSmartAppUrl = function (pcsUrl, store) {
    if (store.smartAppUrl) {
        return;
    }
    var id = store.externalId ? store.externalId : store.storeUuid;
    var url = new URL(pcsUrl);
    var hostname = url.hostname.replace(/^(central-manager\.)/, '');
    store.smartAppUrl = "".concat(url.protocol, "//smartapp.").concat(hostname, "/").concat(id);
};
export default function App() {
    var _this = this;
    var _a = useState(null), stores = _a[0], setStores = _a[1];
    var _b = useState(notAsked()), idToken = _b[0], setIdToken = _b[1];
    var auth0 = useAuth0();
    useEffect(function () {
        if (auth0.isAuthenticated) {
            if (isNotAsked(idToken)) {
                setIdToken(loading());
                auth0
                    .getIdTokenClaims()
                    .then(function (idToken) { return setIdToken(success(idToken)); }) // TODO Use decoder
                    .catch(function (error) {
                    console.error(error);
                    setIdToken(failure(error));
                });
            }
        }
        else if (!auth0.isLoading && !auth0.isAuthenticated && !auth0.error) {
            auth0.loginWithRedirect().catch(console.error);
        }
    }, [auth0, idToken]);
    useEffect(function () {
        if (isSuccess(idToken)) {
            var connectionName_1 = idToken.data['https://pricer.com/connection'];
            var connection = getConfig().auth0Connections.find(function (connection) { return connection.connection === connectionName_1; });
            if (!connection) {
                console.warn("No matching configuration found for connection ".concat(connectionName_1));
                return;
            }
            if (stores !== null) {
                return;
            }
            setStores(Object.fromEntries(connection.pcsInstances.map(function (a) { return [a.displayName, loading()]; })));
            for (var _i = 0, _a = connection.pcsInstances; _i < _a.length; _i++) {
                var pcsInstance = _a[_i];
                (function (_a) { return __awaiter(_this, [_a], void 0, function (_b) {
                    var accessToken, stores_1, e_1;
                    var audience = _b.audience, displayName = _b.displayName, url = _b.url;
                    return __generator(this, function (_c) {
                        switch (_c.label) {
                            case 0:
                                _c.trys.push([0, 3, , 4]);
                                return [4 /*yield*/, auth0
                                        .getAccessTokenSilently({
                                        authorizationParams: { audience: audience }
                                    })
                                        .catch(function (reason) {
                                        console.error(reason);
                                        return auth0.getAccessTokenWithPopup({
                                            authorizationParams: { audience: audience }
                                        });
                                    })
                                        .catch(function (reason) {
                                        console.error(reason);
                                        auth0.loginWithRedirect({
                                            authorizationParams: { audience: audience }
                                        });
                                    })];
                            case 1:
                                accessToken = _c.sent();
                                if (!accessToken) {
                                    return [2 /*return*/];
                                }
                                return [4 /*yield*/, getAvailableStores(accessToken, url)];
                            case 2:
                                stores_1 = _c.sent();
                                setStores(function (prevState) {
                                    var nextState = prevState ? __assign({}, prevState) : {};
                                    nextState[displayName] = success(stores_1);
                                    return nextState;
                                });
                                return [3 /*break*/, 4];
                            case 3:
                                e_1 = _c.sent();
                                console.error(displayName, e_1);
                                setStores(function (prevState) {
                                    var nextState = prevState ? __assign({}, prevState) : {};
                                    // @ts-expect-error Error handling
                                    nextState[displayName] = failure(e_1);
                                    return nextState;
                                });
                                return [3 /*break*/, 4];
                            case 4: return [2 /*return*/];
                        }
                    });
                }); })(pcsInstance);
            }
        }
    }, [auth0, idToken, stores]);
    var content;
    if (auth0.isLoading || stores === null) {
        content = (_jsxs("div", { children: [_jsx("h2", { className: "subtitle", children: "Authenticating" }), _jsx("progress", { className: "progress" })] }));
    }
    else if (auth0.error) {
        content = (_jsx("div", { className: "notification is-danger", children: auth0.error.message }));
    }
    else {
        if (Object.keys(stores).length === 0) {
            content = (_jsx("div", { children: _jsxs("div", { className: "block", children: [_jsx("div", { className: "notification is-info is-light", children: "No stores could be found for the current logged in user." }), _jsxs("p", { children: ["If you believe this is an error, please contact", ' ', _jsx("a", { href: "mailto:pim@pricer.com", children: "Pricer Plaza Support" }), "."] })] }) }));
        }
        else {
            content = (_jsx("div", { children: Object.entries(stores).map(function (_a) {
                    var pcsDisplayName = _a[0], stores = _a[1];
                    return (_jsxs("div", { className: "section", style: {
                            paddingLeft: 0,
                            paddingRight: 0
                        }, children: [_jsx("h2", { className: "title is-4", children: pcsDisplayName }, pcsDisplayName), (function (pcsDisplayName, stores) {
                                if (isSuccess(stores)) {
                                    if (stores.data.length === 0) {
                                        return (_jsx("div", { className: "notification is-info is-light", children: "No stores could be found for the current logged in user." }));
                                    }
                                    else {
                                        return (_jsx("div", { className: "buttons", children: stores.data.map(function (store) { return (_jsx("a", { className: "button is-primary is-fullwidth", href: store.smartAppUrl, children: store.externalId
                                                    ? "".concat(store.name, " \u2013 ").concat(store.externalId)
                                                    : store.name }, store.storeUuid)); }) }));
                                    }
                                }
                                else if (isLoading(stores)) {
                                    return (_jsxs("div", { children: [_jsx("h2", { className: "subtitle", children: "Loading stores" }), _jsx("progress", { className: "progress", title: "Loading stores..." })] }));
                                }
                                else if (isNotAsked(stores)) {
                                    return (_jsxs("div", { children: [_jsx("h2", { className: "subtitle", children: "Loading stores" }), _jsx("progress", { className: "progress", title: "Not asked..." })] }));
                                }
                                else {
                                    return (_jsx("div", { className: "notification is-danger is-light", children: stores.error.message }));
                                }
                            })(pcsDisplayName, stores)] }, pcsDisplayName));
                }) }));
        }
    }
    return (_jsxs("div", { children: [_jsx("div", { style: {
                    display: 'flex',
                    flexDirection: 'row-reverse',
                    padding: 10,
                    backgroundColor: '#d7d7d7'
                }, children: _jsx("button", { className: classNames('button', { 'is-loading': auth0.isLoading }), onClick: function () {
                        return auth0.logout({ logoutParams: { returnTo: window.location.href } });
                    }, children: "Sign out" }) }), _jsxs("section", { className: "section", style: {
                    minHeight: '100vh',
                    position: 'relative'
                }, children: [_jsxs("div", { className: "container", children: [_jsx("h1", { className: "title", children: "Store selector" }), _jsx("h2", { className: "subtitle", children: isSuccess(idToken)
                                    ? idToken.data.name || idToken.data.nickname
                                    : ' ' }), content] }), _jsx("footer", { style: {
                            position: 'absolute',
                            right: '1.5rem',
                            bottom: '1.5rem',
                            textAlign: 'right',
                            fontFamily: 'monospace',
                            fontSize: 12,
                            color: 'gray'
                        } })] })] }));
}
