import { jsx as _jsx } from "react/jsx-runtime";
import './index.css';
import 'bulma/css/bulma.css';
import { Auth0Provider } from '@auth0/auth0-react';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { getConfig } from './config';
import * as serviceWorker from './serviceWorker';
ReactDOM.render(_jsx(React.StrictMode, { children: _jsx(Auth0Provider, { domain: getConfig().oidcDomain, clientId: getConfig().oidcClientId, authorizationParams: { redirect_uri: window.location.origin }, children: _jsx(App, {}) }) }), document.getElementById('root'));
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
