export function notAsked() {
    return { tag: 'NotAsked' };
}
export function isNotAsked(remoteData) {
    return remoteData.tag === 'NotAsked';
}
export function loading() {
    return { tag: 'Loading' };
}
export function isLoading(remoteData) {
    return remoteData.tag === 'Loading';
}
export function success(data) {
    return { tag: 'Success', data: data };
}
export function isSuccess(remoteData) {
    return remoteData.tag === 'Success';
}
export function failure(error) {
    return { tag: 'Failure', error: error };
}
export function isFailure(remoteData) {
    return remoteData.tag === 'Failure';
}
